import React, { useEffect, useState, useMemo, FunctionComponent } from "react";
import { useLocation, useHistory, useParams } from "react-router";
import { useObserver } from "mobx-react-lite";
import moment from "moment";
import { compose, transduce, map, filter, toArray } from "transducist";
import { ApolloClient } from "apollo-client";
import { NormalizedCacheObject } from "apollo-cache-inmemory";
import client from "../../utils/apolloClient";
import { GET_SNAGS, GET_SNAGS_HEADERS, GET_SNAGS_COUNT } from "../../utils/queries";
import FatTable from "../../components/fatTable/FatTable";
import TableMultiselect from "../../components/fatTable/TableMultiselect";
import TableBoolean from "../../components/fatTable/TableBoolean";
import TableDate from "../../components/fatTable/TableDate";
import Paginate from "../../components/fatTable/Pagination";
import DefaultCells from "../../components/DefaultCells";
import BlockCell from "../../components/BlockCell";
import CommentCell from "../../components/CommentCell";
import StepNameDetails from "../../components/StepNameDetails";
import SnagImages from "../../components/SnagImages";
import TableSnagInformation from "../../components/tableSnagInformation/TableSnagInformation";
import { useSingleValueURLParam } from "../../utils/hooks";
import LoadingSpinner from "../../components/loadingSkelaton/LoadingSpinner";
import { RenderedDate } from '../../components/table/Commonfunctions';
import StatusSnags from "../../components/fatTable/StatusSnags";
import { useStore } from "../../models/ProvideModel";
import { sortingWithPrecedence, captureEventCallback } from "../../utils/utils";
import useStyles from '../../components/fatTable/FatTableStyle'
import config from "../../configs/clientConfig";
import { reaction } from "mobx";
import { getSnapshot, getEnv } from "mobx-state-tree";
import ChecklistReportsPopup from "../../components/ChecklistReports/ChecklistReportsPopup";
import * as Sentry from "@sentry/browser";

const __DEFAULT = 100;

export interface IQueryFilterParams {
  where: Record<string, any>;
  order_by?: { [k: string]: "asc" | "desc" }[];
}
interface IQueryPageParams {
  limit: number;
  offset: number;
}

export type IQueryParams = IQueryFilterParams & Partial<IQueryPageParams>;

interface IColumnOptions {
  id: string;
  name: string;
  filter?: Function;
  Component?: FunctionComponent<{ [K: string]: any; }>;
  Cell?: FunctionComponent;
  print?: boolean;
  value?: string;
  isVisible?: boolean;
  accessor?: string;
  minWidth?: number;
  headerObj?: string;
  excelExport?: Function;
  isExcelField?: boolean;
  data?: { id: string; name: string; }[];
  sort?: (a, b) => number;
  excelExportTitle?: string;
}

function useCustomQueryParameters(
  columns: IColumnOptions[],
  filters: URLSearchParams
) {
  // const { blockId } = useParams();               // Use when block_id is a fk
  const [param, setParam] = useState<IQueryParams>();
  const store = useStore()
  useEffect(() => {
    const sort = filters.getAll("sort");
    const descSort = filters.getAll("sortDesc");
    let variables: IQueryParams = {
      where: { block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } }, row_id: { _eq: "snag_added" } },
      order_by: descSort.length === 0 && sort.length === 0 ? [{ block_id: "asc" }] : [],
    };
    for (const { id: field, filter } of columns) {
      if (sort.length > 0 && sort.includes(field)) {
        variables["order_by"]!.push({ [field]: "asc" });
      }
      if (descSort.length > 0 && descSort.includes(field)) {
        variables["order_by"]!.push({ [field]: "desc" });
      }
      const value = filters.getAll(field);
      if (value.length > 0) {
        if (filter) {
          variables["where"] = {
            ...(variables["where"] || {}),
            [field]: { ...filter.apply(null, value) },
          };
        } else {
          if (value.length === 1) {
            const singleVal = value[0];
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _eq: singleVal },
            };
          } else {
            variables["where"] = {
              ...(variables["where"] || {}),
              [field]: { _in: [...value] },
            };
          }
        }
      }
    }
    setParam({ ...variables });
  }, [filters]);
  return param;
}

function delta(input1: string, input2: string): { _lt: string; _gt: string } {
  if (!(input1 && input2)) {
    throw new Error("date type filters must define a range");
  }
  const dates = [input1, input2].map((d) => moment(d));
  return {
    _lt: moment.max(...dates).endOf('day').toISOString(),
    _gt: moment.min(...dates).toISOString(),
  };
}

function range(input: string): { _gt?: number; _lt?: number } {
  const [clause, value] = input.split(/([0-9]+)/);
  switch (clause) {
    case "lt":
      return { _lt: Number.parseInt(value) };
    case "gt":
    default:
      return { _gt: Number.parseInt(value) };
  }
}

function bool(input: string): { _neq: string; } | { _eq: string; } {
  return input === "false" ? { _eq: "" } : { _neq: "" };
}

function hardBool(input: string): { _is_null: boolean; } {
  return { _is_null: input === "false" ? true : false };
}
export const columns: IColumnOptions[] = [
  { id: "block_id", value: "block", accessor: "block.id", name: "Block", Component: TableMultiselect, print: true, Cell: BlockCell, headerObj: "block", minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['block']['title'] },
  { id: "floor_idx", value: "floor_name", name: "Floor", Component: TableMultiselect, print: true, minWidth: 50, isExcelField: true, excelExport: (elt: any) => elt['floor_name'] },
  { id: "precedence", value: "precedence", name: "Precedence", isVisible: false, Component: TableMultiselect, print: false, minWidth: 100, isExcelField: false, excelExport: (elt: any) => elt['precedence'] },
  { id: "activity_type_id", value: "activity_type_title", name: "Activity Name", Component: TableMultiselect, print: true, isExcelField: true, sort: sortingWithPrecedence, excelExport: (elt: any) => elt['activity_type_title'] },
  { id: "unit_id", value: "unit_title", name: "Unit Title", Component: TableMultiselect, print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['unit_title'] },
  { id: "pour_number", name: "Pour", Component: TableMultiselect, print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['pour_number'] },
  { id: "unit_type_id", value: "unit_type_title", name: "Unit Type", Component: TableMultiselect, minWidth: 50, isExcelField: true, excelExport: (elt: any) => elt['unit_type_title'] },
  { id: "inspection_item_title", name: "Inspection Title", Component: TableMultiselect, print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['inspection_item_title'] },
  { id: "step", name: "Step", Cell: StepNameDetails, isExcelField: true, excelExport: (elt: any) => elt['step'] },
  { id: "status", name: "Status", Component: TableMultiselect, Cell: StatusSnags, print: true, minWidth: 100, isExcelField: true, excelExport: (elt: any) => elt['status'] },
  { id: "description", name: "Description", print: true, minWidth: 150, isExcelField: true, excelExport: (elt: any) => elt['description'] },
  { id: "attachment", name: "Image", Cell: SnagImages, Component: TableBoolean, filter: bool, print: true, isExcelField: true, data: [{ id: "true", name: "Snags with Image" }, { id: "false", name: "Snags without Image" }], excelExport: (elt: any) => typeof elt['attachment'] === 'string' && elt['attachment'].length > 0 ? `${config.baseURL}large/${elt['attachment']}` : "-" },
  { id: "comment_array", accessor: "id", value: "comment_array", filter: hardBool, name: "Comments", Component: TableBoolean, print: true, Cell: CommentCell, minWidth: 250, data: [{ id: "true", name: "Snags with Comments" }, { id: "false", name: "Snags without Comments" }], isExcelField: true, excelExport: (elt: any) => !elt['comment_array'] ? '-' : elt['comment_array'].map(c => c.name + ': ' + c.comment + (c["attachment_type"].toLowerCase() === "image" ? `${config.baseURL}large/${c.attachment}` : "")).join('\n') },
  { id: "last_updated", name: "Last Update", filter: delta, Component: TableDate, Cell: RenderedDate, minWidth: 80, isExcelField: true, excelExport: (elt: any) => moment(elt['last_updated']).format('DD/MM/YYYY') },
  { id: "raised_by_id", name: "Raised By", value: "raised_by_name", Component: TableMultiselect, print: true, isExcelField: true, excelExport: (elt: any) => elt['raised_by_name'] },
  { id: "created_at", name: "Raised On", Component: TableDate, Cell: RenderedDate, filter: delta, print: true, minWidth: 80, isExcelField: true, excelExport: (elt: any) => moment(elt['created_at']).format('DD/MM/YYYY') },
  { id: "phase_id", name: "Phase", minWidth: 80, Component: TableMultiselect, isExcelField: true, excelExport: (elt: any) => elt['phase_id'] },
  { id: "space_type_id", name: "Space Type", Component: TableMultiselect, print: true, isExcelField: true, excelExport: (elt: any) => elt['space_type_id'] }
];

const ExportSnagsPage = () => {
  const [data, setData] = useState<any[]>([]);
  const [headersData, setHeadersData] = useState<{}>({});
  const [totalCount, setTotal] = useState<number>(0);

  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);
  const { search, ...location } = useLocation();
  const store = useStore();
  const { fullScreenMode } = store.responsiveUtils;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { push, replace } = useHistory();
  const params = useCustomQueryParameters(columns, searchParams);
  const { tower, spaceType, phase }: { tower?: string | undefined, spaceType?: string | undefined, phase?: string | undefined } = useParams();
  const pathParams = useMemo(() => spaceType && phase ? tower ? ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase }, "block_id": { "_eq": tower } }) : ({ "space_type_id": { "_eq": spaceType }, "phase_id": { "_eq": phase } }) : ({}), [tower, spaceType, phase]);
  const [print] = useSingleValueURLParam("view", "normal", searchParams);
  const [loading, setloading] = useState<boolean>(false)
  const classes = useStyles({ print });
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const columnsConfig = useMemo(
    () =>
      transduce(
        columns,
        compose(
          filter(({ print: printV }) => (print === "print" ? !!printV : true)),
          filter(({ id }) => {
            switch (id) {
              case 'block_id': return !tower;
              case 'space_type_id': return !spaceType;
              case 'phase_id': return !phase;
              default: return true;
            }
          }),
          filter(({ id }) =>
            id === "pour_number" ? phase === "structures" ? true : false : true
          ),
          filter(({ id }) =>
            headersData[id] ? (headersData[id].length > 0 ? true : false) : true
          ),
          filter(({ isVisible }) => {
            return isVisible === undefined ? true : isVisible;
          }),
          map(({ id, name, sort, Component, Cell, isVisible, accessor, minWidth, headerObj, ...rest }) => ({
            name,
            Header:
              Component && print !== "print"
                ? Component
                : name,
            minWidth: minWidth || 150,
            id,
            accessor: accessor || id,
            Cell: !!Cell ? Cell : DefaultCells,
            headerObj,
            data: headersData[id] ? sort ? headersData[id].slice().sort(sort) : headersData[id] : null,
            ...rest
          })),
          map(({ id, data, ...rest }) => ({
            id,
            ...rest,
            data: id === 'space_type_id' ?
              data?.map(({ id: st }) => ({ id: st, name: store.params.getSpaceTypeName(st.toLowerCase()) }))
              : data
          }))
        ),
        toArray()
      ),
    [headersData, print, tower, spaceType, phase]
  );
  const [offset, setPage] = useSingleValueURLParam<number>(
    "page",
    0,
    searchParams,
    Number.parseInt,
    push,
    location
  );
  const [limit, setSize] = useSingleValueURLParam<number>(
    "pageSize",
    __DEFAULT,
    searchParams,
    Number.parseInt,
    push,
    location
  );

  const blockParams = useMemo(() => searchParams.getAll('block_id'), [search]);

  useEffect(() => {
    if (store.params.phase !== "none" && store.params.spaceType !== "None" && store.params.section === "tasks" && store.params.tower === null && store.menus.blocks?.length > 0 && searchParams.getAll('block_id').length === 0) {
      searchParams.append("block_id", store.menus.blocks[0]?.id)
      push({ ...location, search: searchParams.toString() });
    }
  }, [blockParams]);

  // If no space type or phase, then redirect to first available
  useEffect(() => reaction(() => [store.params.spaceTypes.length, store.currentTower, store.params.phases.length, store.params.section, store.params.phase, store.params.screen, store.params.graphType, store.params.spaceType, store.menus, store.menus.blocks.length], () => {
    if (store.params.phase.toLowerCase() === "none" && store.params.spaceType.toLowerCase() === "none" && store.params.phases.length && store.params.spaceTypes.length) {
      const changes = {
        phase: store.params.phases[0],
        spaceType: store.params.spaceTypes[0].id
      };
      let block = new URLSearchParams();
      if (store.menus.blocks && store.menus.blocks.length && (store.params.tower === null)) {
        block.append("block_id", store.menus.blocks[0]?.id);
      }
      replace(store.params.toPath({ ...getSnapshot(store.params), ...changes, section: 'tasks' }) + `?block_id=${store.menus.blocks[0]?.id}`);
    }
  }), []);

  useEffect(() => {
    if (client.client && store.projectInfo.currentProject?.properties?.length && pathParams['phase_id'] && pathParams['space_type_id'] &&
      store.params.phase.toLowerCase() !== "none" && store.params.spaceType.toLowerCase() !== "none"
    ) {
      store.exportTableFilters.changeHeaderDataLoading(true)
      const query = (client.client as ApolloClient<NormalizedCacheObject>)!
        .watchQuery({ query: GET_SNAGS_HEADERS, variables: { where: { row_id: { _eq: "snag_added" }, block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } }, ...pathParams } } })
        .subscribe(({ data, loading }) => {
          if (!loading) {
            setHeadersData(
              columns.reduce(
                (acc, { id, Component }) =>
                  Component
                    ? { ...acc, [id]: data[id] && data[id].length > 0 ? data[id] : null }
                    : acc,
                {}
              )
            );
            setDataLoading(true);
            store.exportTableFilters.changeHeaderDataLoading(false)
          }
        });
      return () => {
        query.unsubscribe();
      };
    }
  }, [pathParams]);
  // Issue the query
  useEffect(() => {
    if (client.client && params && pathParams['phase_id'] && pathParams['space_type_id'] && store.params.phase.toLowerCase() !== "none" && store.params.spaceType.toLowerCase() !== "none") {
      setloading(true)
      const variables: IQueryParams =
        print === "print"
          ? params
          : { ...params, limit, offset: offset * limit };
      const query = (client.client as ApolloClient<
        NormalizedCacheObject
      >)!.watchQuery<Array<any>, IQueryParams>({
        query: GET_SNAGS,
        variables: { ...variables, where: { ...variables.where, block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } }, ...pathParams } },
        errorPolicy: "all",
      });
      const queryCount = (client.client as ApolloClient<
        NormalizedCacheObject
      >)!.watchQuery<Array<any>, IQueryParams>({
        query: GET_SNAGS_COUNT,
        variables: { where: { ...variables.where, block: { property_id: { _in: store.projectInfo.currentProject.properties.map(({ id }) => id) } }, ...pathParams } },
        errorPolicy: "all",
      });

      const observable = query.subscribe(({ data, loading, errors }) => {
        if (!loading) {
          if (!data["snags"].length) {
            const metadata = { customer: store.auth.customerId, user: store.auth.userId, path: store.params.path, params: JSON.stringify(params.where), section: store.params.section, phase: store.params.phase, spaceType: store.params.spaceType };
            getEnv(store).mixpanel.track("NoData", { ...metadata });
            Sentry.withScope(captureEventCallback(metadata));
          }
          setData(data["snags"]);
          /* setTotal(data["meta"]["total"]["count"]); */
          setloading(false)
        } else if (errors) {
          setloading(false)
          console.error(errors);
        }
      });
      const observableCount = queryCount.subscribe(({ data, loading, errors }) => {
        if (!loading) {
          setTotal(data["meta"]["total"]["count"]);
        } else if (errors) {
          console.error(errors);
        }
      });
      return () => {
        observable.unsubscribe();
        observableCount.unsubscribe();
      };
    }
  }, [params, offset, limit, print, pathParams]);


  useEffect(() => {
    store.downloadpdf.setReportName("TASKS")
    store.downloadpdf.setTotalCount(totalCount)
    store.exportTableFilters.setZeroRows(data.length <= 0 ? true : false)
    store.exportTableFilters.setColumns(columns)
    store.exportTableFilters.setParams(JSON.stringify(params))
    store.exportTableFilters.setPathParams(JSON.stringify(pathParams))
    store.exportTableFilters.setHeadersDataCount(Object.keys(headersData).length ? Object.keys(headersData).length : 0)
  }, [totalCount, params, pathParams, data, Object.keys(headersData).length])

  const mobilefullScreenMode = (fullScreenMode) => {
    return !fullScreenMode ? classes.tablePanelSm : classes.tablePanelFs
  }

  useEffect(() => {
    store.exportTableFilters.setColumnConfig(columnsConfig)
  }, [dataLoading])

  useEffect(() => () => {
    store.exportTableFilters.clearTableFiltersData()
  }, [])

  return useObserver(() => (
    <div
      style={{
        backgroundColor: "#faf9f9",
        flexDirection: "column",
        width: "100%",
        display: "flex",
        flexGrow: 1,
        flexBasis: "auto",
        flexShrink: 1,
        // padding: "0px 20px 0 24px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          flexBasis: "auto",
          // overflow: "scroll",
          flexGrow: 1,
          flexShrink: 1,
          // height:"70vh"
        }}
      >
        <div className={store.responsiveUtils.currentViewport.isLg ? classes.tablePanel : mobilefullScreenMode(store.responsiveUtils.fullScreenMode)}>
          <TableSnagInformation totalCount={totalCount} pathParams={pathParams} params={params} loading={loading} />
          <ChecklistReportsPopup />
          {loading ? <LoadingSpinner bgColor={'#faf9f9'} width={(print !== "print") ? "80%" : "100%"} /> : <FatTable columns={columnsConfig} data={data} print={print} />}
          {
            print !== "print" && (
              <Paginate
                totalCount={totalCount}
                page={offset}
                size={limit}
                setPage={setPage}
                setSize={setSize}
              />
            )}
        </div>
      </div>
    </div >
  ));
};

export default ExportSnagsPage;
