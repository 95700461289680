import gql from "graphql-tag";

// export const GET_BLOCKS = gql`{
// towerList: blockss {
// id
// name
// }
// }`;

// old query that gets all blocks
// export const GET_BLOCKS = gql`{
//     towerList: drvd_block {
//         id
//         name: title
//         property_id
//         progress_details
//         meta
//         description
//         }
//     }`;

// Helper where clause strings.
// const __filterNonEmpty = `where: {id: {_is_null: false}}`;
// const __filterNonEmptyFinishingSummary = `where: {total: {_is_null: false}}`;

const DatesFragment = (
    table: string
): {
    fragments: { plan: ReturnType<typeof gql>; actual: ReturnType<typeof gql> };
} => ({
    fragments: {
        plan: gql`fragment ${table}PlanObj on ${table} {
planEnd: plan_end
planStart: plan_start
}`,
        actual: gql`fragment ${table}ActualObj on ${table} {
actualEnd: actual_end
actualStart: actual_start
}`
    }
});

const GET_STRUCTURES_FRAGMENT = {
    fragments: {
        //progress needs to be grouped by activities
        progress: gql`
      fragment StructuresProgressPerTower on drvd_block {
progressDetails: progress_details
      }
    `,
        columns: gql`
fragment StructuresPours on drvd_pour {
id: pour_number
name: pour_label
}
`,
        rows: gql`
      fragment StructuresFloors on drvd_pour_aggregate {
        rows: nodes {
          id: floor_index
          name: floor_name
        }
      }
    `
    }
};

//actualStart: actual_start
//planEnd: plan_end
const GET_FINISHING_TOWER_FULLSCREEN_FRAGMENT = {
    fragments: {
        columns: gql`
fragment FinishingTowerFullscreenActivities on drvd_activity_status {
id: activity_type_id
name: activity_name
        precedence: activity_precedence
activity {
stage: section_name
}
      }
    `,
        // name: floor_name
        rows: gql`
fragment FinishingTowerFullscreenFloors on drvd_activity_status {
id: floor_idx
name: floor_name
}
`
    }
};

const GET_FINISHING_TOWER_LANDING_FRAGMENT = {
    fragments: {
        columns: gql`
fragment FinishingTowerLandingActivities on drvd_activity_status_summary {
        id: activity_type_id
        name: activity_name
        precedence: activity_precedence
activity {
stage: section_name
}
      }
    `,
        // name: floor_name
        rows: gql`
fragment FinishingTowerLandingTowers on drvd_activity_status_summary_aggregate {
rows: nodes {
id: block_id
block {
name: title
          }
          }
        }
`
    }
};


// name: unit_type
const GET_FINISHING_TCA_LANDING_FRAGMENT = {
    fragments: {
        columns: gql`
fragment FinishingTcaLandingUnitTypes on drvd_tca_overview_mat {
id: unit_type_id
name: unit_type_title
}
`,
        // name: floor_name
        rows: gql`
fragment FinishingTcaLandingFloors on drvd_tca_overview_mat {
id: floor_idx
name: floor_name
}
`
    }
};


// const GET_FINISHING_TCA_FULLSCREEN_FRAGMENT = {
//     fragments: {
//         columns: gql`
// fragment FinishingTcaFullscreenActivities on drvd_tca_details_mat {
// id: work_flow_id
// name: description
// precedence
// }
// `,
//         rows: gql`
// fragment FinishingTcaFullscreenFloors on drvd_tca_details_mat {
// id: floor_idx
// name: floor_name
// `
//     }
// };

// removed meta and description in common props
const __GET_BLOCKS = {
    fragments: {
        common: gql`
      fragment CommonProps on drvd_block {
        id
        name: title
        property_id
      }
    `
    }
};

// New query that does some good, old-fashioned front-loading of all row-columns
// metadata for every phase. Tradeoff convenience(code simplicity, and less
// chances for bugs) for modularity(smaller, more intelligent querying that does
// not block UX needs).

// export const GET_BLOCKS = gql`query TowersPerProperty($propertyList: [String!]){
// towersList: drvd_block(where: { property_id: { _in: $propertyList }, _or: [{ pours: {}}, {activity_statuses: {}}]}) {
// ...CommonProps
// ...StructuresProgressPerTower
// floors_structures: pours_aggregate(${__filterNonEmpty},distinct_on: floor_index) {
// ...StructuresFloors
// }
// columns_structures: pours (${__filterNonEmpty},distinct_on: pour_number){
// ...StructuresPours
// }

// progressDetails_finishing: activity_status_summaries(${__filterNonEmptyFinishingSummary}) {
// ...FinishingProgressPerTower
// }
// floors_finishing: activity_statuses_aggregate(${__filterNonEmpty},distinct_on: floor_idx) {
// ...FinishingFloors
// }
// columns_finishing: activity_statuses (${__filterNonEmpty},distinct_on: activity_type_id){
// ...FinishingActivities
// }
// }
// }
// ${__GET_BLOCKS.fragments.common}
// ${GET_STRUCTURES_FRAGMENT.fragments.progress}
// ${GET_STRUCTURES_FRAGMENT.fragments.rows}
// ${GET_STRUCTURES_FRAGMENT.fragments.columns}
// ${GET_FINISHING_FRAGMENT.fragments.progress}
// ${GET_FINISHING_FRAGMENT.fragments.rows}
// ${GET_FINISHING_FRAGMENT.fragments.columns}
// `;

//          Here be Dragons!
// phase-separated queries, for the future when metadata loading may become expensive.
export const GET_BLOCKS = gql`query BlocksPerProperty($propertyList: [String!]) {
towersList: drvd_block(where: { property_id: { _in: $propertyList }}) {
...CommonProps
}
}
${__GET_BLOCKS.fragments.common}
`;

// Changed to fragment
export const GET_BRICKS = {
    fragments: {
        structuresTower: gql`fragment StructuresTowerBricks on drvd_pour{
id
status
tower: block_id
floor: floor_name
yVal: floor_index
xVal: pour_number
pourLabel: pour_label
lastUpdated: last_updated
progress
progressDetails: progress_details
name: title
...drvd_pourPlanObj
...drvd_pourActualObj
pourType: pour_type
}
${DatesFragment("drvd_pour").fragments.actual}
${DatesFragment("drvd_pour").fragments.plan}
`,
        finishingTowerFullscreen: gql`
fragment FinishingTowerFullscreenBricks on drvd_activity_status{
id
yVal: floor_idx
xVal: activity_type_id
floor: floor_name
completed
inProgress: in_progress
lastUpdated:last_updated
total
tower: block_id
activity: activity_name
precedence: activity_precedence
...drvd_activity_statusPlanObj
...drvd_activity_statusActualObj
}
${DatesFragment("drvd_activity_status").fragments.plan}
${DatesFragment("drvd_activity_status").fragments.actual}
`
        ,
        finishingTowerLanding: gql`
fragment FinishingTowerLandingBricks on drvd_activity_status_summary {
xVal: activity_type_id
yVal: block_id
block {
name: title
}
activity: activity_name
precedence: activity_precedence
inProgress: in_progress
completed
lastUpdated: last_updated
tower: block_id
total
...drvd_activity_status_summaryPlanObj
...drvd_activity_status_summaryActualObj
}
${DatesFragment("drvd_activity_status_summary").fragments.plan}
${DatesFragment("drvd_activity_status_summary").fragments.actual}
`,

        // floor: floor_name
        finishingTcaLanding: gql`
fragment FinishingTcaLandingBricks on drvd_tca_overview_mat {
xVal: unit_type_id
unit_type: unit_type_title
yVal: floor_idx
inProgress: in_progress
completed: done_units
lastUpdated: last_updated
tower: block_id
floor: floor_name
total: total_units
phase: phase_id
spaceType: space_type_id
...drvd_tca_overview_matPlanObj
...drvd_tca_overview_matActualObj
}
${DatesFragment("drvd_tca_overview_mat").fragments.plan}
${DatesFragment("drvd_tca_overview_mat").fragments.actual}
`,
        // floor: floor_name
        // precedence: activity_precedence
        // inprogress instead of in_progress
        // no last_updated
        // planned_x instead of plan_x
        finishingTcaFullscreen: gql`
fragment FinishingTcaFullscreenBricks on drvd_tca_details_mat {
group: unit_type_id
groupName: unit_type_title
yVal: floor_idx
floor: floor_name
xVal: activity_type_id
completed
inProgress: in_progress
total
tower: block_id
activity: activity_name
precedence: activity_precedence
lastUpdated: last_updated
phase: phase_id
spaceType: space_type_id
...drvd_tca_details_matActualObj
}
${DatesFragment("drvd_tca_details_mat").fragments.plan}
${DatesFragment("drvd_tca_details_mat").fragments.actual}
`
    }
};

export const GET_FINISHING_TOWER_BLOCKS = gql`query FinishingTowerBlocks($blocks: [String!]) {
blocks: drvd_block(where: {id: {_in: $blocks}, _and: {activity_status_summaries: {}}},order_by: {title: asc}) {
id
}
}`

export const GET_DASHBOARDS = {
    structuresTower: gql`query PerPropertyStructuresTower($blocks: [String!]){
dashboard: drvd_block(where: { id: { _in: $blocks}, _and:  {pours: {} } }, order_by: {title: asc}) {
  ...CommonProps
  ...StructuresProgressPerTower
rows: pours_aggregate(distinct_on: floor_index) {
  ...StructuresFloors
  }
  columns: pours (distinct_on: pour_number){
  ...StructuresPours
  }
units: pours {
...StructuresTowerBricks
}
  }
  }
  ${__GET_BLOCKS.fragments.common}
  ${GET_STRUCTURES_FRAGMENT.fragments.progress}
  ${GET_STRUCTURES_FRAGMENT.fragments.rows}
  ${GET_STRUCTURES_FRAGMENT.fragments.columns}
${GET_BRICKS.fragments.structuresTower}
  `,
    finishingTowerLanding: gql`query PerPropertyFinishingTowerLanding($blocks: [String!]) {
units: drvd_activity_status_summary(where: { block_id: { _in: $blocks} }) {
...FinishingTowerLandingBricks
}
rows: drvd_activity_status_summary_aggregate(distinct_on: block_id, where: {block_id: {_in: $blocks}}) {
...FinishingTowerLandingTowers
}
columns: drvd_activity_status_summary(distinct_on: activity_type_id, where: {block_id: {_in: $blocks}}) {
...FinishingTowerLandingActivities
}
}
${GET_BRICKS.fragments.finishingTowerLanding}
${GET_FINISHING_TOWER_LANDING_FRAGMENT.fragments.rows}
${GET_FINISHING_TOWER_LANDING_FRAGMENT.fragments.columns}
`,
    finishingTowerFullscreen: gql`query PerBlockFinishingTowerFullscreen($block: String!){
rows: drvd_activity_status(distinct_on: floor_idx, where: {block_id: {_eq: $block}}) {
...FinishingTowerFullscreenFloors
}
columns: drvd_activity_status (distinct_on: activity_type_id, where: {block_id: {_eq: $block}}){
...FinishingTowerFullscreenActivities
}
units: drvd_activity_status (where: {block_id: {_eq: $block}}){
...FinishingTowerFullscreenBricks
}
}
${__GET_BLOCKS.fragments.common}
${GET_FINISHING_TOWER_FULLSCREEN_FRAGMENT.fragments.rows}
${GET_FINISHING_TOWER_FULLSCREEN_FRAGMENT.fragments.columns}
${GET_BRICKS.fragments.finishingTowerFullscreen}
`,
    finishingTcaLanding: gql`query PerTabPerBlockFinishingTcaLanding($blocks: [String!], $tab: String!, $phase: String!, $spaceType: String!){
dashboard: drvd_block(where: { id: { _in: $blocks} }) {
  ...CommonProps
rows: tca_overviews (distinct_on: floor_idx, where: {tab_id: {_eq: $tab}, phase_id: {_eq: $phase}, space_type_id: {_eq: $spaceType}}) {
...FinishingTcaLandingFloors
}
columns:  tca_overviews (distinct_on: unit_type_id, where: {tab_id: {_eq: $tab}, phase_id: {_eq: $phase}, space_type_id: {_eq: $spaceType}}){
...FinishingTcaLandingUnitTypes
  }
units: tca_overviews(where: {tab_id: {_eq: $tab}, phase_id: {_eq: $phase}, space_type_id: {_eq: $spaceType}}) {
...FinishingTcaLandingBricks
  }
  }
  }

  ${__GET_BLOCKS.fragments.common}
${GET_FINISHING_TCA_LANDING_FRAGMENT.fragments.rows}
${GET_FINISHING_TCA_LANDING_FRAGMENT.fragments.columns}
${GET_BRICKS.fragments.finishingTcaLanding}
`,
    /* eventually use columns and rows*/
    finishingTcaFullscreen: gql`query PerTabPerBlockFinishingTcaFullscreen($block: String!, $tab: String!, $phase: String!, $spaceType: String!) {
units: drvd_tca_details_mat(where: {_and: [{block_id: {_eq : $block}}, {tab_id: {_eq: $tab}}, {phase_id: {_eq: $phase}}, {space_type_id: {_eq: $spaceType}}]}, order_by: {unit_type_id: asc}) {
...FinishingTcaFullscreenBricks
}
}
${GET_BRICKS.fragments.finishingTcaFullscreen}
  `
};

export const GET_TABS = gql`query getTabs($blocks: [String!], $project: String!) {
tabs: drvd_tca_overview_mat(distinct_on: [tab_id, phase_id, space_type_id], where: {block_id: {_in: $blocks}, config_overview_tab: {_or: [{project_id: {_eq: $project}}, {project_id: {_is_null: true}}]}}){
tab: config_overview_tab {
  id
  name: title
  type: graph_type
}
spaceType: space_type_id
phase: phase_id

}
}`;

// TODO: Change to a better name. In fact, refactor the hundred blocks calls.
export const GET_TOWERS = {
    finishingTcaFullscreen: gql`query PerTabBlocksFinishingTcaFullscreen($tab: String!, $blocks: [String!], $phase: String!, $spaceType: String!) {
blocks: drvd_block(where: {id: {_in: $blocks}, tca_details: {tab_id: {_eq: $tab}, phase_id: {_eq: $phase}, space_type_id: {_eq: $spaceType}}}) {
id
name: title
details: tca_details {
type: graph_type
}
}
}
`
};

/*

  export const GET_BLOCKS_METADATA = {
  structures: gql`query MetaPerPropertyStructure($propertyList: [String!]) {
  towersList: drvd_block(where: { property_id: { _in: $propertyList }, _and: { _and: { pours: {} } } }) {
  id
  floors: pours_aggregate(distinct_on: floor_index) {
  ...StructuresFloors
  }
  columns: pours(distinct_on: pour_number){
  ...StructuresPours
  }
  }
  }
  ${ GET_STRUCTURES_FRAGMENT.fragments.rows}
  ${ GET_STRUCTURES_FRAGMENT.fragments.columns} `,
  finishing: gql`query MetaPerPropertyFinishing($propertyList: [String!]){
  towersList: drvd_block(where: { property_id: { _in: $propertyList }, _and: { _and: { activity_statuses: {} } } }) {
  id
  floors: activity_statuses_aggregate(distinct_on: floor_idx) {
  ...FinishingFloors
  }
  columns: activity_statuses(distinct_on: activity_type_id){
  ...FinishingActivities
  }
  }
  }
  ${ GET_FINISHING_FRAGMENT.fragments.rows}
  ${ GET_FINISHING_FRAGMENT.fragments.columns}
  `
  };

*/

// property_id
// progress_structure
// progress_finishing
// progress_handover

// export const GET_UNIT_TYPES = gql`{
// unit_types {
// name
// id
// }
// }`;

// export const GET_CONFIG = gql`{
// report_configs {
// id
// info
// }
// }`;

// following didn't work:
// currentMilestone
// onGoingActivities
// snags
// number
// meta
// block_id

// export const GET_METADATA = {
//     structures: gql`
// query StructuresMetaPerTower($block_id: String!) {
// columns: drvd_pour(
// distinct_on: pour_number
// where: { block_id: { _eq: $block_id } }
// ) {
// ...StructuresPours
// }
// rows: drvd_pour_aggregate(distinct_on: floor_index) {
// ...StructuresFloors
// }
// }
// ${GET_STRUCTURES_FRAGMENT.fragments.columns}
// ${GET_STRUCTURES_FRAGMENT.fragments.rows}
// `,
//     finishing: gql`query FinishingMetaPerTower($block_id: String!){
// columns: drvd_activity_status (distinct_on: activity_type_id, where: {block_id: {_eq: $block_id}}){
// ...FinishingActivities
// }
// rows: drvd_pour_aggregate(${__filterNonEmpty},distinct_on: floor_index) {
// ...FinishingFloors
// }
// }
// ${GET_FINISHING_FRAGMENT.fragments.columns}
// ${GET_FINISHING_FRAGMENT.fragments.rows}
// `
// };
// export const GET_ROWS_POURS = gql`query FloorsPerStructuresTower($block_id: String!) {
// payload: drvd_pour_aggregate(distinct_on: floor_index, where: { block_id: { _eq: $block_id } }) {
// minMax: aggregate {
//       maxVal:max {
// max: floor_index
// }
//       minVal:min {
// min: floor_index
// }
//     }
// rows: nodes {
// id: floor_index
// name: floor_name
// }
//     }
// }
// `;

// export const GET_COLUMNS_POURS = gql`query PoursPerStructuresTower($block_id: String!) {
// payload: drvd_pour_aggregate(distinct_on: pour_number, where: { block_id: { _eq: $block_id } }) {
// columns: nodes {
// id: pour_number
//     }
//   }
// }`;

export const GET_POUR_SUMMARY = gql`
query SummaryByUnitId($unit_id: String!) {
pours: drvd_pour(where: { id: { _eq: $unit_id } }) {
id
progress_details
meta
}
}
`;

//Add title?
// Add dates?
// Is lastUpdated appropriate?: Resolved NO
// Will there be a status enum like in the pours? Resolved: Calculate based on
// completed === total
// For progressDetails (tile numeral for each box), how will we get units?:
// Resolved No
// such colum
// unit_type
// floor: floor_name
// ...drvd_activity_statusPlanObj
// ...drvd_activity_statusActualObj

//floor: floor_name

// export const GET_UNIT_BY_ID = gql`query PoursPerTower($clause: String!) {
//     units(query: $clause){
//     id
//     status
//     block_id
//     name
//     yVal: floor
//     property_id
//     dueDate: plan_end
//     lastUpdated
//     progress
//     xVal: pour_number
//     }
//     }`;

// * Query for getting units avtivities based on block
// * for summary pop up by block
export const GET_UNIT_ACTIVITIES_BY_BLOCK = gql`
query unitActivitiesByBlock($block_id: String!, $activity_type_id: String!) {
units: drvd_unit_activity_details_mat(
where: {
block_id: { _eq: $block_id }
activity_type_id: { _eq: $activity_type_id }
},
order_by: { unit_name: asc }
) {
activity_type_id
block_id
floor_idx
floor_name
status
unit_id
current_step
current_user_designation
current_user_name
unit_activity_id
unit_name
activity_name
actualEnd:actual_end
actualStart:actual_start
planEnd:plan_end
planStart:plan_start
lastUpdated: last_updated
snag_counts {
count_closed
count_for_review
count_open
}
}
}
`;

// * Query for getting units avtivities based on floor
// * for summary pop up by block and floor
export const GET_UNIT_ACTIVITIES_BY_FLOOR = gql`
query unitActivitiesByFloor($block_id: String!, $activity_type_id: String!, $floor_idx: drvd_unit_activity_details_mat_bool_exp!, $phase: String!, $spaceType: String!) {
units: drvd_unit_activity_details_mat(
where: {_and: [
{block_id: { _eq: $block_id }},
{activity_type_id: { _eq: $activity_type_id }},
{phase_id: {_eq: $phase}},
{space_type_id: {_eq: $spaceType}},
$floor_idx
]},
order_by: { unit_name: asc }
) {
activity_type_id
block_id
floor_idx
floor_name
status
unit_id
current_step
current_user_designation
current_user_name
unit_activity_id
unit_name
activity_name
actualEnd:actual_end
actualStart:actual_start
planEnd:plan_end
planStart:plan_start
lastUpdated: last_updated
snag_counts {
count_closed
count_for_review
count_open
}
}
}
`;

export const GET_UNIT_ACTIVITIES_BY_FLOOR_BY_UNIT_TYPE = gql`
query unitActivitiesByFloorByUnitType($block_id: String!, $unit_type_id: String!, $floor_idx: drvd_unit_activity_details_mat_bool_exp!, $phase: String!, $spaceType: String!) {
units: drvd_unit_activity_details_mat(
where: {_and: [
{block_id: { _eq: $block_id }},
{unit_type_id: { _eq: $unit_type_id }},
{phase_id: {_eq: $phase}},
{space_type_id: {_eq: $spaceType}},
$floor_idx
]},
order_by: { unit_name: asc }
) {
activity_type_id
block_id
floor_idx
floor_name
status
unit_id
current_step
current_user_designation
current_user_name
unit_activity_id
unit_name
activity_name
actualEnd:actual_end
actualStart:actual_start
planEnd:plan_end
planStart:plan_start
lastUpdated: last_updated
}
}
`;
// ** Activity Info 
// * Query for getting avtivities summary based on block by activity presedence
export const GET_ACTIVITIES_SUMMARY_BY_BLOCK = gql`
query activitiesSummaryByBlock($block_id: String!) {
activities: drvd_activity_status_summary(
where: {
block_id: { _eq: $block_id }
},
order_by: { activity_precedence: asc }
) {
activity_name
activity_type_id
block_id
total
completed
in_progress
activity_precedence
actualEnd:actual_end
actualStart:actual_start
planEnd:plan_end
planStart:plan_start
lastUpdated: last_updated
unit_type
snag_counts {
count_closed
count_for_review
count_open
}
}
}
`;

// * Query for getting avtivities based on block and unit
export const GET_ACTIVITIES_BY_BLOCK_BY_UNIT = gql`
query activitiesByBlockByUnit($block_id: String!, $unit_id: String!) {
activities: drvd_unit_activity_details_mat(
where: {
block_id: { _eq: $block_id }
unit_id: { _eq: $unit_id }
},
order_by: { activity_name: asc }
) {
activity_type_id
block_id
status
unit_id
floor_idx
floor_name
current_step
current_user_designation
current_user_name
unit_activity_id
unit_name
activity_name
actualEnd:actual_end
actualStart:actual_start
planEnd:plan_end
planStart:plan_start
lastUpdated: last_updated
snag_counts {
count_closed
count_for_review
count_open
}
}
}
`;

// ** Unit Info
// * Query for getting units based on block and activity
export const GET_UNITS_BY_BLOCK_BY_ACTIVITY = gql`
query unitsByBlockByActivity($block_id: String!, $activity_type_id: String!) {
units: drvd_unit_activity_details_mat(
where: {
block_id: { _eq: $block_id }
activity_type_id: { _eq: $activity_type_id }
},
order_by: { activity_name: asc }
) {
activity_type_id
block_id
status
unit_id
current_step
current_user_designation
current_user_name
unit_activity_id
unit_name
activity_name
activity_type_id
actualEnd:actual_end
actualStart:actual_start
planEnd:plan_end
planStart:plan_start
lastUpdated: last_updated
snag_counts {
count_closed
count_for_review
count_open
}
}
}
`;

// * Query for getting units summary based on block 
export const GET_UNITS_SUMMARY_BY_BLOCK = gql`
query unitsSummaryByBlock($block_id: String!) {
units: drvd_unit_status_summary(
where: {
block_id: { _eq: $block_id }
},
order_by: { unit_id: asc }
) {
block_id
block_name
completed
in_progress:wip
not_started
total
unit_id
unit_name
wip
snag_counts {
count_closed
count_for_review
count_open
}
}
}
`;

// * Query for getting steps by unit activity id
export const GET_STEP_BY_UNIT_ACTIVITY_ID = gql`
query stepsByUnitActivityId($unit_activity_id: String!) {
steps: drvd_unit_activity_log (
where: {
unit_activity_id: { _eq: $unit_activity_id }
},
order_by: { step_number: asc }
) {
actual_start
current_user_name
status
step_name
step_number
unit_activity_id,
block,
unit_name
floor_name
activity_name
}
}
`;

export const GET_PROGRESS_UPDATE = gql`
query ProgressUpdate($activity_id: String!) {
master2_activity_history(
where: {activity_id: {_eq: $activity_id }, 
data: {_is_null: false}},
order_by: { created_at: desc }
) {
activity_id
created_at
created_by
data
}
}
`;

export const GET_STEP_BY_UNIT_ACTIVITY_IDD = gql`
query stepsByUnitActivityId($limit: Int, $offset: Int, $order_by: [drvd_unit_activity_log_order_by!], $where: drvd_unit_activity_log_bool_exp!) {
data: drvd_unit_activity_log(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
step_name
status
actual_start
current_user_name
data
photo_added_by
photo_added_on
}
}
`;

export const GET_STEP_BY_UNIT_ACTIVITY_IDD_HEADERS = gql`
query stepsByUnitActivityId($where: drvd_unit_activity_log_bool_exp!) {
step_name: drvd_unit_activity_log(distinct_on: step_name, where:{_and: [{step_name: {_is_null: false}}, $where]}) {id: step_name }
status: drvd_unit_activity_log(distinct_on: status, where:{_and: [{status: {_is_null: false}}, $where]}) {id: status }
current_user_name: drvd_unit_activity_log(distinct_on: current_user_name, where:{_and: [{current_user_name: {_is_null: false}}, $where]}) {
id: current_user_name
}
}
`;

// {
//   query stepsByUnitActivityId($unit_activity_id: String!) {
//     steps: drvd_unit_activity_log (
//       where: {
//         unit_activity_id: { _eq: $unit_activity_id }
//       },
//       order_by: { step_number: asc }
//     ) 

// Example variables:
//  {"limit": 100, "offset": 10, "order_by": [{"id": "asc"}], "where": {"date_closed": {"_is_null": false}}}

// export const GET_SNAGS = gql`
// query snags($limit: Int, $offset: Int, $order_by: [master_tbl_snag_raised_order_by!], $where: master_tbl_snag_raised_bool_exp!) {
// snags: master_tbl_snag_raised(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
// assigned_user_id
// attachment
// category_title
// checklist_id
// date_closed
// date_raised
// days_left
// description
// inspection_item_title
// raised_by
// room_name
// status
// work_item_title
// sync_ct
// sync_ut
// id
// }
// meta: master_tbl_snag_raised_aggregate(where: $where) {
// total: aggregate {count}
// }
// }
// `;

export const GET_SNAGS = gql`
query snags($limit: Int, $offset: Int, $order_by: [drvd_snag_info_mat_order_by!], $where: drvd_snag_info_mat_bool_exp!) {
snags: drvd_snag_info_mat(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
block {
id
title
}
block_id
floor_idx
floor_name
unit_id
unit_title
snags_age: created_at
category_title
unit_activity_id
unit_type_id
unit_type_title
activity_type_id
activity_type_title
step
room_name
pour_number
status
pending_with
description
attachment
attachment_v2
comment_array
last_updated
raised_by_name
created_at
inspection_item_title
phase_id
id
space_type_id
}
}
`;

export const GET_SNAGS_COUNT = gql`
query snagsCount($where: drvd_snag_info_mat_bool_exp!) {
meta: drvd_snag_info_mat_aggregate(where: $where) {
total: aggregate {count}
}
}
`;

export const GET_SNAGS_SUMMARY = gql`
query snagsSummary($where: drvd_snag_info_mat_bool_exp!) {
block_id: drvd_snag_info_mat_aggregate(distinct_on: block_id, where:{_and: [{block_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
unit_id: drvd_snag_info_mat_aggregate(distinct_on: unit_id, where:{_and: [{unit_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
activity_type_id: drvd_snag_info_mat_aggregate(distinct_on: activity_type_id, where:{_and: [{activity_type_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
unit_type_id: drvd_snag_info_mat_aggregate(distinct_on: unit_type_id, where:{_and: [{unit_type_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
}
`;


// * Query for getting powerbi reports for the user
export const GET_USER_GROUP_REPORT_DETAILS = gql`
query userGroupReportDetails($project: String!) {
reports: drvd_user_group_reports(order_by: { dashboard_name: asc }, where: {_or: [{project_id: {_eq: $project }}, {project_id: {_is_null: true}}]}) {
dashboardName: dashboard_name
embedConfig: embed_config
provider,
group
}
}`;
export const GET_MENU_DETAILS = gql`
query menuNavigationDetails($propertyList: [String!]) {
phaseSpace: drvd_phase_space_for_property(where: {
property_id: { _in: $propertyList }
}) {
property_id
phase: phase_id
phaseName: phase_title
spaceType: space_type_id
spaceTypeName: space_type_title
showDashboards: show_dashboards
reports: portal_menus {
spaceTypeId: space_type_id
title
meta
}
}
blocks: drvd_block( where: {
  property_id:{_in: $propertyList } 
  }) {
  id: id
  name: title
  }
}
`;

// export const GET_PHASES = gql`
// query phases($propertyList: [String!]) {
// phase: drvd_phase_space_for_property(distinct_on: phase_id, where: {
//   property_id: { _in: $propertyList }
// }) {
//   id: phase_id
//   name: phase_title
// }
// }
// `;

// export const GET_SPACE_TYPE = gql`
// query spaceType($propertyList: [String!]) {
// spaceType: drvd_phase_space_for_property(distinct_on: space_type_id,order_by: {space_type_id: desc}, where: {
//   property_id: { _in: $propertyList }
// }) {
//   id: space_type_id
//   name: space_type_title
// }
// }
// `;

export const GET_TOWER_DROPDOWN = gql`
query towers($propertyList: [String!]) {
blocks: drvd_block( where: {
property_id:{_in: $propertyList } 
}) {
id: id
name: title
}
}
`;

export const GET_SNAGS_HEADERS = gql`
query snagHeaders($where: drvd_snag_info_mat_bool_exp!) {
block_id: drvd_snag_info_mat(distinct_on: block_id, order_by: {block_id: asc}, where:{_and: [{block_id: {_is_null: false}}, $where]}) {
block{
id
name: title
}
}
floor_idx: drvd_snag_info_mat(distinct_on: floor_idx, order_by: {floor_idx: asc}, where:{_and:[{floor_idx: {_is_null: false}}, $where]}) {
id: floor_idx
name: floor_name
}
inspection_item_title: drvd_snag_info_mat(distinct_on: inspection_item_title, order_by: {inspection_item_title: asc}, where:{_and:[{inspection_item_title: {_is_null: false}}, $where]}) {
id: inspection_item_title
}
unit_id: drvd_snag_info_mat(distinct_on: unit_id, order_by: {unit_id: asc}, where:{_and:[{unit_id: {_is_null: false}}, $where]}) {
id: unit_id
name: unit_title
}
room_name: drvd_snag_info_mat(distinct_on: room_name, order_by: {room_name: asc}, where:{_and:[{room_name: {_is_null: false}}, $where]}) {id: room_name }
unit_type_id: drvd_snag_info_mat(distinct_on: unit_type_id, order_by: {unit_type_id: asc}, where:{_and:[{unit_type_id: {_is_null: false}}, $where]}) {
id: unit_type_id
name: unit_type_title
}
pour_number: drvd_snag_info_mat(distinct_on: pour_number, where:{_and: [{pour_number: {_is_null: false}}, $where]}, order_by: {pour_number: asc}) {id: pour_number}
activity_type_id: drvd_snag_info_mat(distinct_on: activity_type_id, order_by: {activity_type_id: asc}, where:{_and:[{activity_type_id: {_is_null: false}}, $where]}) {
id: activity_type_id
name: activity_type_title
precedence
}

category_title: drvd_snag_info_mat(distinct_on: category_title, order_by: {category_title: asc}, where: {_and:[{category_title: {_is_null: false}}, $where]}) {
id: category_title
}
status: drvd_snag_info_mat(distinct_on: status, order_by: {status: asc}, where:{_and:[{status: {_is_null: false}}, $where]}) {id: status }
pending_with: drvd_snag_info_mat(distinct_on: pending_with, order_by: {pending_with: asc}, where: {_and:[{pending_with: {_is_null: false}}, $where]}) {
id: pending_with
name: pending_with
}
raised_by_id: drvd_snag_info_mat(distinct_on: raised_by_id, order_by: {raised_by_id: asc}, where:{_and:[{raised_by_id: {_is_null: false}}, $where]}) {
id: raised_by_id
name: raised_by_name
}
phase_id: drvd_snag_info_mat(distinct_on: phase_id, order_by: {phase_id: asc}, where:{_and:[{phase_id: {_is_null: false}}, $where]}) {
id: phase_id
}
space_type_id: drvd_snag_info_mat(distinct_on: space_type_id, order_by: {space_type_id: asc}, where:{_and:[{space_type_id: {_is_null: false}}, $where]}) {
id: space_type_id
}
}
`

export const GET_UNIT_INFO = gql`
query unitInfo($limit: Int, $offset: Int, $order_by: [drvd_unit_activity_details_mat_order_by!], $where: drvd_unit_activity_details_mat_bool_exp!) {
unitInfo: drvd_unit_activity_details_mat(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
block {
id
title
}
block_id
floor_idx
floor_name
unit_name
space_type_title
unit_id
pour_number
unit_type_id
unit_type_title
activity_name
unit_activity_id
current_step
status
status_desc
current_user_id
current_user_name
last_updated
plan_start
actual_start
plan_end
actual_end
phase_id
phase_title
space_type_id
activity_type_id
progress
form_data {
  id
  form_config {
    form_description
  }
  form_id
  input_data
  }
form_permission
}
meta: drvd_unit_activity_details_mat_aggregate(where: $where) {
total: aggregate {
count
}
}
}
`;

export const GET_UNIT_INFO_FOR_EXCEL = gql`
query unitInfoForExcel($order_by: [drvd_unit_activity_details_mat_order_by!], $where: drvd_unit_activity_details_mat_bool_exp!) {
unitInfo: drvd_unit_activity_details_mat(order_by: $order_by, where: $where) {
block {
id
title
}
block_id
floor_idx
floor_name
unit_name
space_type_title
unit_id
pour_number
unit_type_id
unit_type_title
activity_name
unit_activity_id
current_step
status
status_desc
current_user_id
current_user_name
last_updated
plan_start
actual_start
plan_end
actual_end
phase_id
phase_title
space_type_id
activity_type_id
progress
snag_counts {
count_closed
count_for_review
count_open
}
}
}
`;

export const GET_FORM_DATA_AND_FIELD_PERMISSIONS = gql`
query getFormDataAndField_Permissions($unitActivityId: String!, $formId: String!, $formJson: jsonb) {
formDetails: drvd_unit_activity_details_mat(where: {unit_activity_id: {_eq: $unitActivityId}, form_permission: {_contains: $formJson}}){
unit: unit_name
activity: activity_name
permissions: form_permission
formData: form_data(where: {form_id: {_eq: $formId}}) {
lastUpdated: last_updated_at
data: input_data
}
}
fieldConfig: master2_form_field_config(order_by: {position: asc}, where: {form_id: {_eq: $formId}}) {
field: field_config {
id
description: field_description
data
}
form: form_config {
name: form_description
}
}
}
`;

export const FIELD_DATA = gql`
query fieldData($limit: Int, $offset: Int, $order_by: [master2_field_config_order_by!],$where: master2_field_config_bool_exp!) {
field_config: master2_field_config(limit: $limit, offset: $offset, order_by: { form_field_configs_aggregate: { avg: { position: asc } } }, where: $where) {
data
field_description
id
}
}
`
export const GET_UNIT_ACTIVITY_SNAGS_COUNT = gql`
query unitSnagsCount($ids: [String!]) {
snagCounts: drvd_unit_activity_snag_counts(where: {unit_activity_id: {_in: $ids}}) {
unit_activity_id
count_closed
count_for_review
count_open
}
}
`;

export const GET_UNIT_ACTIVITY_SUMMARY = gql`
query unitActivitySummary($where: drvd_unit_activity_details_mat_bool_exp!) {
block_id: drvd_unit_activity_details_mat_aggregate(distinct_on: block_id, where:{_and: [{block_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
unit_id: drvd_unit_activity_details_mat_aggregate(distinct_on: unit_id, where:{_and: [{unit_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
activity_type_id: drvd_unit_activity_details_mat_aggregate(distinct_on: activity_type_id, where:{_and: [{activity_type_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
}
`;

export const GET_UNIT_INFO_HEADERS = gql`
query unitHeaders($where: drvd_unit_activity_details_mat_bool_exp!) {
block_id: drvd_unit_activity_details_mat(distinct_on: block_id, where:{_and: [{block_id: {_is_null: false}}, $where]}, order_by: {block_id: asc}) {
block {
id
name: title
}
}
floor_idx: drvd_unit_activity_details_mat(distinct_on: floor_name, where:{_and: [{floor_idx: {_is_null: false}}, $where]}, order_by: {floor_name: asc}) {
id: floor_idx
name: floor_name
}
phase_id: drvd_unit_activity_details_mat(distinct_on: phase_id, where:{_and: [{phase_id: {_is_null: false}}, $where]}, order_by: {phase_id: asc}) {id: phase_title
name: phase_title
}
space_type_id: drvd_unit_activity_details_mat(distinct_on: space_type_title, where:{_and: [{space_type_id: {_is_null: false}}, $where]}, order_by: {space_type_title: asc}) {
id: space_type_id
name: space_type_title
}
unit_id: drvd_unit_activity_details_mat(distinct_on: unit_id, where:{_and: [{unit_id: {_is_null: false}}, $where]}, order_by: {unit_id: asc}) {id: unit_id
name: unit_name
}
pour_number: drvd_unit_activity_details_mat(distinct_on: pour_number, where:{_and: [{pour_number: {_is_null: false}}, $where]}, order_by: {pour_number: asc}) {id: pour_number}
unit_type_id: drvd_unit_activity_details_mat(distinct_on: unit_type_id, where:{_and: [{unit_type_id: {_is_null: false}}, $where]}) {
id: unit_type_id
name: unit_type_title
}
activity_type_id: drvd_unit_activity_details_mat(distinct_on: activity_type_id, where:{_and: [{activity_type_id: {_is_null: false}}, $where]}) {
id: activity_type_id
name: activity_name
precedence
}
status_desc: drvd_unit_activity_details_mat(distinct_on: status_desc, where:{_and: [{status_desc: {_is_null: false}}, $where]}, order_by: {status_desc: asc}) {id: status_desc }
current_user_name: drvd_unit_activity_details_mat(distinct_on: current_user_name, where:{_and: [{current_user_name: {_is_null: false}}, $where]}, order_by: {current_user_name: asc}) {
id: current_user_name
}
}
`;

export const GET_ACT_BY_BLOCK_BY_UNIT_HEADERS = gql`
query unitHeaders {
activity_type_id: drvd_unit_activity_details_mat(distinct_on: activity_type_id, where:{activity_type_id: {_is_null: false}}) {
id: activity_type_id
name: activity_name
}
current_step: drvd_unit_activity_details_mat(distinct_on: current_step, where:{current_step: {_is_null: false}}) {id: current_step }
status_desc: drvd_unit_activity_details_mat(distinct_on: status_desc, where:{status_desc: {_is_null: false}}) {id: status_desc }
current_user_name: drvd_unit_activity_details_mat(distinct_on: current_user_name, where:{current_user_name: {_is_null: false}}) {
id: current_user_name
}
}
`;
// * Query for getting avtivities based on block and unit
export const GET_ACT_BY_BLOCK_BY_UNIT = gql`
query unitInfo($limit: Int, $offset: Int, $order_by: [drvd_unit_activity_details_mat_order_by!], $where: drvd_unit_activity_details_mat_bool_exp!) {
unitInfo: drvd_unit_activity_details_mat(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
activity_name
current_step
status
status_desc
current_user_name
last_updated
plan_start
actual_start
plan_end
actual_end
phase_id
phase_title
space_type_id
activity_type_id
unit_activity_id
snag_counts {
count_closed
count_for_review
count_open
}
}
meta: drvd_unit_activity_details_mat_aggregate(where: $where) {
total: aggregate {
count
}
}
}
`;

// * Query for getting units summary based on block
export const GET_UNITS_BY_BLOCK = gql`
query unitsSummaryByBlock($limit: Int, $offset: Int, $order_by: [drvd_unit_status_summary_order_by!], $where: drvd_unit_status_summary_bool_exp!) {
units: drvd_unit_status_summary(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
block_id
block_name
completed
in_progress:wip
not_started
total
unit_id
unit_name
snag_counts {
count_closed
count_for_review
count_open
}
}
meta: drvd_unit_status_summary_aggregate(where: $where) {
total: aggregate {
count
max {
total
}
}
}
}
`;

export const GET_UNITS_BY_BLOCK_HEADERS = gql`
query unitLegacyHeaders($where: drvd_unit_status_summary_bool_exp!){
unit_id: drvd_unit_status_summary(distinct_on: unit_id, where:{_and: [{unit_id: {_is_null: false}}, $where]}) {
id: unit_id
name: unit_name
}
}
`;


// Checklist report queries
export const GET_CHECKLIST_REPORTS = gql`
query checklistReports($limit: Int, $offset: Int, $order_by: [master2_checklist_reports_order_by!], $where: master2_checklist_reports_bool_exp!) {
checklist_reports: master2_checklist_reports(distinct_on: report_key,limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
unit_activity_id
block_id
block_name
unit_id
unit_name
floor_idx
floor_name
activity_type_id
activity_type_name
unit_type_id
unit_type_name
from_node
to_node
inspection_type
report_template_id
report_key
report_data
report_date
}
}
`;


export const GET_CHECKLIST_REPORTS_HEADERS = gql`
query checklistReportsHeaders($where: master2_checklist_reports_bool_exp!) {
block_id: master2_checklist_reports(distinct_on: block_id, where:{_and: [{block_id: {_is_null: false}}, $where]}, order_by: {block_id: asc}) {
id: block_id
name: block_name
}
floor_idx: master2_checklist_reports(distinct_on: floor_name, where:{_and: [{floor_idx: {_is_null: false}}, $where]}, order_by: {floor_name: asc}) {
id: floor_idx
name: floor_name
}
unit_id: master2_checklist_reports(distinct_on: unit_id, where:{_and: [{unit_id: {_is_null: false}}, $where]}, order_by: {unit_id: asc}) {
id: unit_id
name: unit_name
}
unit_type_id: master2_checklist_reports(distinct_on: unit_type_id, where:{_and: [{unit_type_id: {_is_null: false}}, $where]}) {
id: unit_type_id
name: unit_type_name
}
activity_type_id: master2_checklist_reports(distinct_on: activity_type_id, where:{_and: [{activity_type_id: {_is_null: false}}, $where]}) {
id: activity_type_id
name: activity_type_name
}
inspection_type: master2_checklist_reports(distinct_on: inspection_type, where: {_and: [{inspection_type: {_is_null: false}}, $where]}) {
id: inspection_type
}
}
`;

export const GET_CHECKLIST_REPORTS_COUNT = gql`
query checklistReportsCount($where: master2_checklist_reports_bool_exp!) {
meta: master2_checklist_reports_aggregate(where: $where) {
total: aggregate {count}
}
}
`;

export const GET_CHECKLIST_ALL_REPORTS = gql`
query checklistAllReports($where: master2_checklist_reports_bool_exp!) {
data: master2_checklist_reports(where: $where) {
id: report_key
unit: unit_name
activity: activity_type_name
block_name
}
}
`;


export const GET_CHECKLIST_REPORTS_SUMMARY = gql`
query checklistReportsSummary($where: master2_checklist_reports_bool_exp!) {
block_id: master2_checklist_reports_aggregate(distinct_on: block_id, where:{_and: [{block_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
unit_id: master2_checklist_reports_aggregate(distinct_on: unit_id, where:{_and: [{unit_id: {_is_null: false}}, $where]}) {
total: aggregate {
count
}
}
}
`;
export const GET_ALL_FORMS_DATA = gql`
query getAllFormsData ($formId: String!) {
formData: master2_form_data(where: {form_id: {_eq: $formId}}, order_by: [{activity_id: asc}]) {
activity: activity_id
lastModified: last_updated_at
inputData: input_data
}
}
`;
export const GET_ALL_FORM_FIELDS = gql`
query getAllFormFields ($formFields: [String!]) {
formFields: master2_field_config(where: {id: {_in: $formFields}}) {
id
description: field_description
data
}
}
`;

export const GET_ACTIVITY_DATA_FOR_FORM = gql`
query getActivityDataForForm($where: drvd_unit_activity_details_mat_bool_exp!, $order_by: [drvd_unit_activity_details_mat_order_by!]) {
activities: drvd_unit_activity_details_mat(where: $where, order_by: $order_by) {
unit_activity_id
block_id
block {
id
title
}
unit_id
unit_name
activity_type_id
activity_name
floor_idx
floor_name
status
status_desc
unit_type_id
unit_type_title
phase_id
phase_title
space_type_id
space_type_title
current_user_name
}
}
`;

export const GET_FORMS = gql`
query getForms($propertyList: [String!]) {
forms: master2_form_data(distinct_on: form_id, where: {unitActivity: {block: {property_id: {_in: $propertyList}}}}) {
id: form_id
form_config {
name: form_description
fieldOrders: form_field_configs {
fieldId: field_id
position
}
}
}
}
`;

export const GET_ACTIVITY_HEADERS_FOR_FORM = gql`
query unitActivityHeaders($where: drvd_unit_activity_details_mat_bool_exp!) {
block_id: drvd_unit_activity_details_mat(distinct_on: block_id, where:{_and: [{block_id: {_is_null: false}}, $where]}, order_by: {block_id: asc}) {
block {
id
name: title
}
}
floor_idx: drvd_unit_activity_details_mat(distinct_on: floor_name, where:{_and: [{floor_idx: {_is_null: false}}, $where]}, order_by: {floor_name: asc}) {
id: floor_idx
name: floor_name
}
phase_id: drvd_unit_activity_details_mat(distinct_on: phase_id, where:{_and: [{phase_id: {_is_null: false}}, $where]}, order_by: {phase_id: asc}) {id: phase_title
name: phase_title
}
space_type_id: drvd_unit_activity_details_mat(distinct_on: space_type_title, where:{_and: [{space_type_id: {_is_null: false}}, $where]}, order_by: {space_type_title: asc}) {
id: space_type_id
name: space_type_title
}
unit_id: drvd_unit_activity_details_mat(distinct_on: unit_id, where:{_and: [{unit_id: {_is_null: false}}, $where]}, order_by: {unit_id: asc}) {id: unit_id
name: unit_name
}
pour_number: drvd_unit_activity_details_mat(distinct_on: pour_number, where:{_and: [{pour_number: {_is_null: false}}, $where]}, order_by: {pour_number: asc}) {id: pour_number}
unit_type_id: drvd_unit_activity_details_mat(distinct_on: unit_type_id, where:{_and: [{unit_type_id: {_is_null: false}}, $where]}) {
id: unit_type_id
name: unit_type_title
}
activity_type_id: drvd_unit_activity_details_mat(distinct_on: activity_type_id, where:{_and: [{activity_type_id: {_is_null: false}}, $where]}) {
id: activity_type_id
name: activity_name
precedence
}
status_desc: drvd_unit_activity_details_mat(distinct_on: status_desc, where:{_and: [{status_desc: {_is_null: false}}, $where]}, order_by: {status_desc: asc}) {id: status_desc }
current_user_name: drvd_unit_activity_details_mat(distinct_on: current_user_name, where:{_and: [{current_user_name: {_is_null: false}}, $where]}, order_by: {current_user_name: asc}) {
id: current_user_name
}

}
`;
